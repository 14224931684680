/* globals.css */

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

:root {
  --font-cairo: 'Cairo', sans-serif;
  --font-quicksand: "Quicksand", sans-serif;
}

.poppins {
  font-family: 'Poppins', sans-serif;
}

.tajawal {
  font-family: 'Tajawal', sans-serif;
}

.quicksand {
  font-family: "Quicksand", sans-serif;
}

.cairo {
  font-family: 'Cairo', sans-serif;
}

body {
  margin: 0;
  line-height: inherit;
}

user agent stylesheet body {
  display: block;
  margin: 8px;
}

/* .multiCoiseSlider .swiper-wrapper {
  align-items: center;
}

.multiCoiseSlider .swiper-wrapper .swiper-slide {
  width: auto !important;
} */

.custom-border-rad:first-child {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}


.custom-border-rad:last-child {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }

  .gradient-blue {
    background: linear-gradient(90deg, #2775FF 0%, #88C6ED 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  .bg-gradient-blue {
    background: linear-gradient(179.94deg, #1096FF 0.05%, rgba(16, 150, 255, 0) 492.73%);

  }

}

@media (max-width: 800px) {
  .footer-logo-para {
    flex-basis: 100% !important;
  }

  .footer-links {
    gap: 3rem !important;
    flex-basis: 100% !important;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
  }
}

@media (max-width: 768px) {
  .modal-not-btn {
    order: 2;
  }

  .modal-whp-btn {
    order: 1;
  }

  .mobile-search-width {
    width: 70%;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.floating_wp-button {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 1000;
}

.floating_wp-button img {
  height: 3.5rem;
}

@media (max-width: 768px) {
  .floating_wp-button {
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    z-index: 1000;
  }

  .footer-align-view {
    flex-wrap: wrap;
  }

  .reponsive-card {
    padding: 10px;
  }

 
}